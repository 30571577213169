import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import InfoSection from '../components/InfoSection';
import BannerSection from '../components/BannerSection';
import {
  homeObjOneA,
  homeObjOneB,
  homeObjOneC,
  homeObjOneD
} from '../components/InfoSection/Data';
import {
  bannerObjThree,
  bannerObjFour
} from '../components/BannerSection/Data';


const DonationPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <BannerSection {...bannerObjThree} />
      <InfoSection {...homeObjOneA} />
      <InfoSection {...homeObjOneB} />
      <InfoSection {...homeObjOneC} />
      <InfoSection {...homeObjOneD} />
      <BannerSection {...bannerObjFour} />
      <Footer />
    </>
  );
}

export default DonationPage
