import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarHardLink,
  SidebarRoute
} from './SidebarElements';

//<SidebarLink to="ourImpact" onClick={toggle}>Our Impact</SidebarLink>
//<SidebarLink to="events" onClick={toggle}>Events</SidebarLink>
//<SidebarLink to="waysToGive" onClick={toggle}>Ways To Give</SidebarLink>


const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle} >
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarHardLink to="/" onClick={toggle}>Home</SidebarHardLink>
          <SidebarHardLink to="/brisStory" onClick={toggle}>Bri's Story</SidebarHardLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to="/donate">Donate</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar
