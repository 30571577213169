import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItem,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  WebsiteRights,
  SocialIconLink
} from './FooterElements'

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItem>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="/donate">How it works</FooterLink>
              <FooterLink to="/donate">Testimonials</FooterLink>
              <FooterLink to="/donate">Careers</FooterLink>
              <FooterLink to="/donate">Investors</FooterLink>
              <FooterLink to="/donate">Terms of Service</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLinkTitle>Videos</FooterLinkTitle>
              <FooterLink to="/donate">Submit Video</FooterLink>
              <FooterLink to="/donate">Ambassadors</FooterLink>
              <FooterLink to="/donate">Agency</FooterLink>
              <FooterLink to="/donate">Influencer</FooterLink>
            </FooterLinkItem>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItem>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to="/donate">Contact</FooterLink>
              <FooterLink to="/donate">Support</FooterLink>
              <FooterLink to="/donate">Destinations</FooterLink>
              <FooterLink to="/donate">Sponsorships</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink to="/donate">Instagram</FooterLink>
              <FooterLink to="/donate">Facebook</FooterLink>
              <FooterLink to="/donate">Youtube</FooterLink>
              <FooterLink to="/donate">Twitter</FooterLink>
            </FooterLinkItem>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>BKF</SocialLogo>
            <WebsiteRights>The Brianna Kupfer Foundation © {new Date().getFullYear()} All rights reserved.<br/>For more info contact us at briannakupferfoundation@gmail.com</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer
